<template>
  <div class="wrapper px-3 pb-2 pt-3">
    <v-row class="ml-1">
      <div
        class="d-flex align-center modal-button"
        v-if="!newEmailContactModal"
        @click="newEmailContactModal = true"
      >
        <v-icon
          color="#2cb56b"
          class="mr-2"
        >
          mdi-plus-box
        </v-icon>
        <span>Dodaj inny kontakt</span>
      </div>
      <div
        v-else
        class="d-flex align-center justify-space-between w-100 mb-2"
      >
        <div
          class="
            d-flex
            align-center
            justify-center
            modal-button modal-button--confirm
            pa-1
          "
          :class="{ 'modal-button--disabled': !valid || !allValues }"
          @click="addNewEmailContact"
        >
          <v-icon
            class="mr-2"
            color="#2cb56b"
            size="20"
          >
            mdi-checkbox-marked-outline
          </v-icon>
          <span>Dodaj</span>
        </div>
        <div
          class="d-flex align-center justify-center modal-button pa-1"
          @click="newEmailContactModal = false"
        >
          <v-icon
            class="mx-2"
            color="#f86262"
          >
            mdi-close
          </v-icon>
        </div>
      </div>
    </v-row>
    <div
      class="mt-1"
      v-if="newEmailContactModal"
    >
      <v-form
        v-model="valid"
        lazy-validation
      >
        <div class="d-flex px-2 mt-6">
          <v-text-field
            v-model="newEmailContactData.firstName"
            class="mr-4"
            placeholder="Wpisz imię odbiorcy"
            label="Imię"
            :rules="[rules.required]"
            outlined
            multiple
            hide-details
            dense
          />
          <v-text-field
            v-model="newEmailContactData.lastName"
            class="mr-4"
            label="Nazwisko"
            placeholder="Wpisz nazwisko odbiorcy"
            :rules="[rules.required]"
            outlined
            multiple
            hide-details
            dense
          />
          <v-text-field
            v-model="newEmailContactData.email"
            label="E-mail"
            placeholder="Wpisz e-mail odbiorcy"
            :rules="[rules.required, rules.email]"
            outlined
            multiple
            hide-details
            dense
          />
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import rules from '@/utils/validators'

export default {
  data() {
    return {
      rules,
      valid: true,
      newEmailContactModal: false,
      newEmailContactData: {
        firstName: null,
        lastName: null,
        email: null,
      },
    }
  },
  methods: {
    addNewEmailContact() {
      if (this.valid) {
        this.$emit('add-new-email-contact', this.newEmailContactData)
        this.newEmailContactData = {
          firstName: null,
          lastName: null,
          email: null,
        }
        this.newEmailContactModal = false
      }
    },
  },
  computed: {
    allValues() {
      return (
        this.newEmailContactData.firstName &&
        this.newEmailContactData.lastName &&
        this.newEmailContactData.email
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #8080801a;
  max-height: 130px;
  border-radius: 4px;
}
.modal-button {
  cursor: pointer;
  &--confirm {
    font-weight: 700;
    border-radius: 4px;
    color: #2cb56b;
    background-color: #e0f7ec;
  }
  &--disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}
</style>
